<template>
  <base-loading v-if="isLoading" />
  <div class="container-fluid" v-else>
    <!-- Page Heading -->
    <div class="row mt-3">
      <div class="col-12">
        <base-breadcrumb :pageName="currentPageName" :storeName="''" />
      </div>
    </div>
    <div class="row no-gutters justify-content-end my-3">
      <div class="col-4 p-1">
        <base-date-filter :date="date" @handleDate="handleDate" />
      </div>
      <div class="col-4 p-1 d-flex justify-content-end">
        <div
          :class="{
            'config-selected': compareDate(
              startDateGlobalConfig,
              getThisWeekDate
            ),
          }"
          @click="
            getReports({
              start_date: getThisWeekDate,
              end_date: getCurrentDate,
            });
            startDateGlobalConfig = getThisWeekDate;
          "
          class="text-gray-800 btn btn-default border bg-white stc-config-btn p-2 font-weight-bold w-50 text-center rounded-0"
        >
          Cette semaine
        </div>
        <div
          :class="{
            'config-selected': compareDate(
              startDateGlobalConfig,
              getThisMonthDate
            ),
          }"
          @click="
            getReports({
              start_date: getThisMonthDate,
              end_date: getCurrentDate,
            });
            startDateGlobalConfig = getThisMonthDate;
          "
          class="text-gray-800 btn btn-default border bg-white stc-config-btn p-2 font-weight-bold w-50 text-center rounded-0"
        >
          Ce mois
        </div>

        <div
          :class="{
            'config-selected': compareDate(
              startDateGlobalConfig,
              getThisYearDate
            ),
          }"
          @click="
            getReports({
              start_date: getThisYearDate,
              end_date: getCurrentDate,
            });
            startDateGlobalConfig = getThisYearDate;
          "
          class="text-gray-800 btn btn-default border bg-white stc-config-btn p-2 font-weight-bold w-50 text-center rounded-0"
        >
          cette année
        </div>
      </div>
    </div>
    <!-- Content Row -->
    <div class="row no-gutters">
      <!-- Earnings (Daily) -->
      <div class="col-lg-6 p-1">
        <div class="card p-3 h-100" :class="{ 'cart-chart': is_reLoading }">
          <daily-chart :chartData="dailychartData"  v-if="!is_reLoading" />
          <base-grow-loading v-else />
        </div>
      </div>

      <!-- Earnings (Monthly) -->
      <div class="col-lg-6 p-1">
        <div class="card p-3 h-100" :class="{ 'cart-chart': is_reLoading }">
          <monthly-chart :chartData="monthlyChartData" v-if="!is_reLoading" />
          <base-grow-loading v-else />
        </div>
      </div>
    </div>
    <div class="row no-gutters">
      <div class="col-md-4 p-1">
        <div class="card p-3 h-100" :class="{ 'cart-chart': is_reLoading }">
          <CustomerReport
            :data="topTenSelesByCustomer"
            v-if="!is_reLoading"
          ></CustomerReport>
          <base-grow-loading v-else />
        </div>
      </div>
      <div class="col-md-4 p-1">
        <div class="card p-3 h-100">
          <TopItems :data="topTenSelesByItem" v-if="!is_reLoading"></TopItems>
          <base-grow-loading v-else />
        </div>
      </div>
      <div class="col-md-4 p-1">
        <div class="card p-3 h-100">
          <categories-chart
            :chartData="categoriesChartData"
            v-if="!is_reLoading"
          />
          <base-grow-loading v-else />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import DailyChart from "@/views/statistics/DailyChart.vue";
import MonthlyChart from "@/views/statistics/MonthlyChart.vue";
import CategoriesChart from "@/views/statistics/CategoriesChart.vue";
import TopItems from "@/views/statistics/TopItems.vue";
import CustomerReport from "@/views/statistics/CustomerReport.vue";
import ReportService from "@/services/report.service";
import moment from "moment";

const reportService = new ReportService();

export default {
  name: "Statistics",
  components: {
    DailyChart,
    MonthlyChart,
    CategoriesChart,
    TopItems,
    CustomerReport,
  },
  data() {
    return {
      date: [],
      isLoading: false,
      is_reLoading: false,
      reports: {
        daily: null,
        monthly: null,
        items: null,
        customers: null,
        categories: null,
        topTenSelesByCustomer: [],
        topTenSelesByItem: [],
      },
      startDateGlobalConfig: null,
      currentPageName: "Statistiques",
      dailychartData: null,
      monthlyChartData: null,
      categoriesChartData: null,
    };
  },
  created() {
    this.isLoading = true;
    this.startDateGlobalConfig = this.getThisMonthDate;
    this.getReports({
      start_date: this.startDateGlobalConfig,
      end_date: this.getCurrentDate,
    });
    this.date = [this.startDateGlobalConfig, this.getCurrentDate];
    this.isLoading = false;
  },
  methods: {
    async getReports(dateRange) {
      dateRange.start_date = this.dateFormat(dateRange.start_date);
      dateRange.end_date = this.dateFormat(dateRange.end_date);
     
      this.is_reLoading = true;
      
      try {
        const res = await reportService.get(dateRange);

        this.dailychartData = {
          labels: _.map(res.data.daily, "date"),
          datasets: [
            {
              label: "Total Recettes par jour",
              backgroundColor: "#1a8ccd",
              data: _.map(res.data.daily, "total"),
            },
          ],
        };

        this.monthlyChartData = {
          labels: _.map(res.data.monthly, "month"),
          datasets: [
            {
              label: "Total Recettes par mois",
              backgroundColor: "#1a8ccd",
              data: _.map(res.data.monthly, "total"),
            },
          ],
        };

        this.reports = res.data;
        this.categoriesChartData = {
          labels: _.map(res.data.categories, "name"),
          datasets: [
            {
              backgroundColor: this.generateRandomColor(
                res.data.categories.length
              ),
              data: _.map(res.data.categories, "total"),
            },
          ],
        };

        this.topTenSelesByItem = _.orderBy(
          res.data.items,
          ["total"],
          ["desc"]
        ).slice(0, 10);
        this.topTenSelesByCustomer = _.orderBy(
          res.data.customers,
          ["total"],
          ["desc"]
        ).slice(0, 10);
        this.date = [dateRange.start_date, dateRange.end_date];

        this.is_reLoading = false;
      } catch (err) {
        console.log(err);
      }
    },
    generateRandomColor(n) {
      let colors = [];
      for (let i = 1; i <= n; i++) {
        colors.push("#" + Math.floor((i + 6) / 100 * 0xFFFFFF).toString(16));
      }
      if (colors.length === 1) {
        return "#1a8ccd";
      }
      return colors;
    },
    handleDate(modelData) {
      this.getReports({
        start_date: this.dateFormat(modelData[0]),
        end_date: this.dateFormat(modelData[1]),
      });
    },
    compareDate(date1, date2) {
      return date1 === date2;
    },
    dateFormat(date) {
      return moment(date).format("YYYY-MM-DD");
    },
  },
  computed: {
    getCurrentDate() {
      return this.dateFormat(new Date());
    },
    getThisWeekDate() {
      return this.dateFormat(moment().clone().startOf("week"));
    },
    getThisMonthDate() {
      return this.dateFormat(moment().clone().startOf("month"));
    },
    getThisYearDate() {
      return this.dateFormat(moment().clone().startOf("year"));
    },
  },
};
</script>
<style scoped>
.config-selected,
.stc-config-btn:hover {
  background-color: #1a8ccd !important;
  color: white !important;
  -webkit-transition: all 500ms ease;
  -moz-transition: all 500ms ease;
  -ms-transition: all 500ms ease;
  -o-transition: all 500ms ease;
  transition: all 500ms ease;
}
.cart-chart {
  height: 18rem !important;
}
</style>
