<template>
  <div>
    <h5 class="text-gray-800 card-title mt-3 text-center">
      Top 10 articles
    </h5>
    <table class="table table-striped table-sm mt-4">
      <tbody>
      <tr
        v-for="item in items"
        :key="item"
      >
        <td>{{ item.name }}</td>
        <td class="text-right">{{ item.quantity }}</td>
        <td class="text-right">{{ formatNumber(item.total) }}</td>
      </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import ReportService from "@/services/report.service";
const reportService = new ReportService();

export default {
  name: "TopItems",
  props: ["startDateGlobalConfig", "data"],
  data() {
    return {
      items: [],
    };
  },
  created() {
    this.items = this.data;
  },
  methods: {
    getTopItemsReports() {
      this.items = this.data;
    },
    formatNumber(number) {
      return this.$filters.numFormat(number);
    },
  },
  watch: {
    data(newVlaue) {
      this.items = newVlaue;
    },
  },
};
</script>
