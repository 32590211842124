<template>
  <div>
    <h5 class="card-title mt-3 text-center">Top 10 clients</h5>

    <table class="table table-striped table-sm mt-4">
      <tbody>
      <tr
        v-for="customer in customers"
        :key="customer"
      >
        <td>{{ customer.name }}</td>
        <td class="text-right">{{ formatNumber(customer.total) }}</td>
      </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  name: "CustomerReport",
  props: ["data"],
  watch: {
    data: function () {
      this.getCustomerReports();
    },
  },
  data() {
    return {
      customers: [],
    };
  },
  mounted() {
    this.getCustomerReports();
  },
  methods: {
    getCustomerReports() {
      this.customers = this.data;
    },
    formatNumber(number) {
      return this.$filters.numFormat(number);
    },
  },
};
</script>
