<template>
  <Bar
    :chart-data="chartData"
    :chart-options="chartOptions"
    :height="height"
  />
</template>

<script>
import { Bar } from 'vue-chartjs'
import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale } from 'chart.js'
ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale)
export default {
  name: "dailyChart",
  components: {
    Bar,
  },
  props: ['chartData'],
  data(){
    return {
      chartOptions: {
        responsive: true
      },
      height: 200,
    }
  }
}
</script>