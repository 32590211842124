<template>
  <div>
    <h5 class="text-gray-800 card-title mt-3 text-center">
      Les Ventes par categorie
    </h5>
    <Pie
      :chart-data="chartData"
      :chart-options="chartOptions"
      :height="height"
    />
  </div>
</template>

<script>
import { Pie } from "vue-chartjs";
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  ArcElement,
  CategoryScale,
} from "chart.js";

ChartJS.register(Title, Tooltip, Legend, ArcElement, CategoryScale);

export default {
  name: "categoryChart",
  components: {
    Pie,
  },
  props: ["chartData"],
  data() {
    return {
      chartOptions: {
        responsive: true,
      },
      height: 250,
    };
  },
};
</script>
