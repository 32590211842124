<template>
  <Line
    :chart-data="chartData"
    :chart-options="chartOptions"
    :height="height"
  />
</template>

<script>
import { Line } from 'vue-chartjs'
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  LineElement,
  LinearScale,
  PointElement,
  CategoryScale,
} from 'chart.js';

ChartJS.register(
  Title,
  Tooltip,
  Legend,
  LineElement,
  LinearScale,
  PointElement,
  CategoryScale
);
export default {
  name: "monthlyChart",
  components: {
    Line
  },
  props: ['chartData'],
  data(){
    return {
      chartOptions: {
        responsive: true
      },
      height: 200,
    }
  }
}
</script>